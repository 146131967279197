import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { IndividualActionSchema, UpdateIndividualActionSchema } from '@greenisland-api';
import { Box, capitalize, FormControlLabel, Switch, Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { calculateTicks } from '@greenisland-common/helpers/timeConverters';

import { getDefaultIndividualActionNumberInputProps } from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionVoucherForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const { register, watch, errors, control } = useFormContext<UpdateIndividualActionSchema>();

  const isVoucherEnabled = !!watch('voucher.enabled');
  const isInternalVoucherEnabled = !!watch('voucher.internalVoucher');

  return (
    <IndividualActionFormWrapper enabled={isVoucherEnabled} name="voucher.enabled" listItemText={t('voucher')}>
      <FormControlLabel
        label={t('action.internalVoucher')}
        sx={{ '.MuiTypography-root': { ml: 1 } }}
        control={
          <Controller
            name="voucher.internalVoucher"
            control={control}
            render={({ value, onChange }) => (
              <Switch edge="end" onChange={event => onChange(event.target.checked)} checked={value} />
            )}
          />
        }
      />
      {!isInternalVoucherEnabled && (
        <>
          <Input
            size="small"
            name="voucher.productGroupId"
            label={capitalize(t('productGroupIdVoucher'))}
            inputRef={register({
              required: false,
              setValueAs: value => (value ? Number(value) : undefined),
            })}
            InputProps={getDefaultIndividualActionNumberInputProps(1)}
            disabled={!isVoucherEnabled}
            error={Boolean(errors?.voucher?.productGroupId)}
            helperText={errors?.voucher?.productGroupId?.message}
            fullWidth={true}
            defaultValue={individualAction?.voucher?.productGroupId}
          />
          <Input
            size="small"
            name="voucher.productId"
            label={capitalize(t('productIdVoucher'))}
            inputRef={register({
              required: false,
              setValueAs: value => (value ? Number(value) : undefined),
            })}
            InputProps={getDefaultIndividualActionNumberInputProps(1)}
            disabled={!isVoucherEnabled}
            error={Boolean(errors?.voucher?.productId)}
            helperText={errors?.voucher?.productId?.message}
            fullWidth={true}
            defaultValue={individualAction?.voucher?.productId}
          />
        </>
      )}
      <Input
        size="small"
        name="voucher.productValue"
        label={capitalize(t('productValueVoucher'))}
        inputRef={register({
          required: isVoucherEnabled,
          setValueAs: value => (value ? Number(value) : undefined),
        })}
        required={isVoucherEnabled}
        InputProps={getDefaultIndividualActionNumberInputProps(1)}
        disabled={!isVoucherEnabled}
        error={Boolean(errors?.voucher?.productValue)}
        helperText={errors?.voucher?.productValue?.message}
        fullWidth={true}
        defaultValue={individualAction?.voucher?.productValue}
      />
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          size="small"
          name="voucher.durationTicks"
          label={capitalize(t('durationVoucher'))}
          inputRef={register({
            required: false,
            setValueAs: value => (value ? calculateTicks(Number(value), 'days') : undefined),
          })}
          InputProps={{
            inputProps: {
              type: 'number',
              pattern: '[0-9]*',
            },
          }}
          disabled={!isVoucherEnabled}
          error={Boolean(errors?.voucher?.durationTicks)}
          helperText={errors?.voucher?.durationTicks?.message}
          fullWidth={true}
          defaultValue={individualAction?.voucher?.durationTicks}
        />
        <Tooltip title={t('voucherDuration.Explanation')}>
          <Box>
            <FontAwesomeIcon size="lg" icon={faCircleQuestion} sx={{ ml: 2, cursor: 'pointer' }} />
          </Box>
        </Tooltip>
      </Box>
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionVoucherForm;
