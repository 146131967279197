import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EBoostTypeSchema, PagedBoostOverviewsSchema, useGetBoosts } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, capitalize, Typography } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import GlobalBoostsFormDialog from './forms/GlobalBoostFormDialog';
import { formatCurrency, getDateFromUnix, getDurationFromSeconds } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

type Row = PagedBoostOverviewsSchema;

const PartialAggregationFunctionSchema = {
  Sum: 'Sum',
  Max: 'Max',
} as const;

type EAggregationFunctionSchema =
  (typeof PartialAggregationFunctionSchema)[keyof typeof PartialAggregationFunctionSchema];

type AggregationFunctionKeys = {
  [key in EAggregationFunctionSchema]: string;
};

const aggregationFunctionKeys: AggregationFunctionKeys = {
  [PartialAggregationFunctionSchema.Sum]: 'boost.global.aggregationFunction.sum',
  [PartialAggregationFunctionSchema.Max]: 'boost.global.aggregationFunction.max',
};

const PAGE_SIZE = 10;

const GlobalBoostsPage = () => {
  const { t } = useTranslation();

  const canReadGlobalBoosts = usePermission(OnlineCasinoPermissions.getBoosts);
  const canCreateGlobalBoosts = usePermission(OnlineCasinoPermissions.createGlobalBoost);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: globalBoosts,
    isError,
    isLoading,
  } = useGetBoosts(
    {
      boostType: EBoostTypeSchema.Global,
      page: page,
      pageSize: pageSize,
    },
    { query: { enabled: canReadGlobalBoosts } }
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        headerName: capitalize(t('boost.table.header.name')),
        field: 'name',
        sortable: false,
        minWidth: 220,
        flex: 0.2,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.payoutType')),
        field: 'payoutType',
        sortable: false,
        minWidth: 160,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.multiplier')),
        field: 'factor',
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.aggregationFunction')),
        field: 'aggregationFunction',
        sortable: false,
        minWidth: 300,
        flex: 0.3,
        renderCell: ({ value }) => {
          return (
            <Typography variant="body2">
              {t(aggregationFunctionKeys[value as EAggregationFunctionSchema]) || '-'}
            </Typography>
          );
        },
      },
      {
        headerName: capitalize(t('boost.table.header.isLoyaltyDependent')),
        field: 'isLoyaltyDependent',
        sortable: false,
        minWidth: 200,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        headerName: capitalize(t('boost.table.header.maxPayout')),
        field: 'maxPayout',
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value ? formatCurrency(value) : '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.totalPayout')),
        field: 'totalPayout',
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value ? formatCurrency(value) : '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.contentFilterId')),
        field: 'contentFilterId',
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.startDate')),
        field: 'startDate',
        sortable: false,
        minWidth: 180,
        flex: 0.1,
        renderCell: ({ value }) => (
          <Typography variant="body2">{value && value > 0 ? getDateFromUnix(value) : '-'}</Typography>
        ),
      },
      {
        headerName: capitalize(t('boost.table.header.duration')),
        field: 'duration',
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value ? getDurationFromSeconds(value) : '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.timesAwarded')),
        field: 'timesAwarded',
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('boost.table.header.timesActivated')),
        field: 'timesActivated',
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
    ],
    [t]
  );

  const rows: GridRowsProp = useMemo(
    () =>
      globalBoosts?.items?.map((item, index) => {
        return {
          ...item,
          id: index,
        };
      }) || [],
    [globalBoosts?.items]
  );

  return (
    <>
      <PermissionWrapper
        errorMessage={t('boost.global.errors.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getBoosts}
      >
        <>
          {canCreateGlobalBoosts ? (
            <Button
              variant="contained"
              size="medium"
              sx={{ mb: 4 }}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setIsOpen(true)}
            >
              {t('boost.global.create.button')}
            </Button>
          ) : null}
          <DataGridContainer>
            <LimitedDataGrid
              autoHeight
              density="compact"
              rowHeight={70}
              loading={isLoading}
              columns={columns}
              rows={rows}
              pageSize={pageSize}
              paginationMode="server"
              pagination
              page={globalBoosts?.pagingDetails?.currentPage}
              rowCount={globalBoosts?.pagingDetails?.totalItems}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              components={{ Pagination: DataGridPagination }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      </PermissionWrapper>
      <GlobalBoostsFormDialog
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
      />
    </>
  );
};

export default GlobalBoostsPage;
